<template>
  <div class="multipleUploadImg">
    <el-upload
      action=""
      list-type="picture-card"
      :before-upload="beforeAvatarUpload"
      :on-remove="handleRemove"
      accept=".png,.jpg,.jpeg,"
      :file-list="fileList"
      :http-request="uploadRequest"
    >
      <div class="explain">
        <i class="el-icon-plus"></i>
        <div class="text">上传</div>
      </div>
    </el-upload>
  </div>
</template>

<script>
import { getFileUpload } from '@/api/college'
import to from 'await-to'
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    uploadImg: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fileList: [],
    }
  },
  created() {},
  methods: {
    handleRemove(file, fileList) {
      this.fileList = fileList
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.includes('image')
      if (!isImage) {
        this.$message.error('上传文件类型必须是图片!')
      }
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }
      return isImage && isLt5M
    },
    async uploadRequest(file) {
      const params = new FormData()
      params.append('fileTypeCode', 'F001')
      params.append('file', file.file)
      params.append('fileName', file.file.name)
      const [res, err] = await to(getFileUpload(params))
      if (err) {
        this.fileList.splice(this.fileList.length - 1, 1)
        return this.$message.error(err.msg)
      }
      this.fileList.push({ docName: '', url: res.data })
      console.log(this.fileList)
      this.$emit('input', this.fileList)
      if (this.fileList.length > 5) {
        this.fileList.splice(this.fileList.length - 1, 1)
        this.$message({ type: 'warning', message: '最多上传5张图片' })
      }
      if (this.fileList.length !== 0) return this.uploadImg.clearValidate()
    },
  },
}
</script>

<style scoped lang="scss">
.multipleUploadImg {
  ::v-deep .el-upload-list--picture-card {
    li {
      width: 160px;
      height: 120px;
    }
    img {
      width: 160px;
      height: 120px;
    }
  }
  ::v-deep .el-upload--picture-card {
    width: 160px;
    height: 120px;
    border-radius: 4px;
    background: #f5f5f5;
    border: none;
    line-height: 0;
    .explain {
      width: 100%;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .text {
        font-size: 12px;
        margin-top: 20px;
        color: #979797;
      }
    }
  }
}
</style>
