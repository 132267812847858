<template>
  <div class="add_credentials">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">添加证书项目</span>
      </div>
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-form-item label="证书名称:" prop="certName">
          <el-input
            v-model="form.certName"
            placeholder="请输入2-28字的证书名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="证书分类:" prop="productAttributeCategoryId">
          <el-select
            v-model="form.productAttributeCategoryId"
            placeholder="请选择证书分类"
            clearable
            @change="changeSelect"
          >
            <el-option
              v-for="item in selectTypeList"
              :key="item.productAttributeCategoryId"
              :value="item.productAttributeCategoryId"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发证机构:" prop="issuingAgency">
          <el-input
            v-model="form.issuingAgency"
            placeholder="请输入2-24字的机构名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item v-if="level.length" label="证书等级:" prop="attrList[0].attrs">
          <el-checkbox-group v-model="form.attrList[0].attrs">
            <el-checkbox v-for="(item, index) in level" :key="index" :label="item"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="证书归属地:" prop="addressCode">
          <el-cascader
            ref="myCascader"
            v-model="form.addressCode"
            :options="total"
            :props="{
              value: 'value',
            }"
            placeholder="请选择证书归属地"
            @change="handleAddressChange"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item ref="certAttachList" label="附件:" prop="certAttachList">
          <MultipleUploadImg v-model="form.certAttachList"></MultipleUploadImg>
          <p class="tip">单个文件大小5MB，支持扩展名：.png .jpg.jpeg（最多可上传5个文件）</p>
        </el-form-item>
        <el-form-item label="报名申请表:" prop="applyFormList">
          <upload-file
            v-model="form.applyFormList"
            :file-type="'.docx,.doc'"
            :limit="1"
          ></upload-file>
          <p class="uploadFileText">单个文件大小5MB，支持扩展名.docx .doc, 仅支持上传一个文件</p>
        </el-form-item>
        <el-form-item ref="tinymceRules" label="报名材料说明:" prop="applyMaterial">
          <div>用户证书报名时所需要的材料清单说明</div>
          <tinymce ref="editor" v-model="form.applyMaterial" :width="750" :height="300"></tinymce>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="submit('form')">提交审核</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import Tinymce from '@/components/Tinymce'
import UploadFile from '@/components/UploadFile'
import total from '../../../assets/address/city'
import { createdCertificate, attrSelect } from '@/api/certificate'
import MultipleUploadImg from '@/components/MultipleUploadImg'
import to from 'await-to'
export default {
  components: {
    MultipleUploadImg,
    UploadFile,
    Tinymce,
  },
  data() {
    var checked = (rule, value, callback) => {
      if (value.length == 0) {
        return callback(new Error('请选择证书等级'))
      } else {
        callback()
      }
    }
    return {
      total,
      form: {
        attrList: [
          {
            attrs: [],
            productAttributeDictId: null,
          },
        ],
      },
      selectTypeList: [],
      level: [],
      loading: false,
      rules: {
        certName: [
          { required: true, message: '请输入证书名称', trigger: 'blur' },
          { min: 2, max: 28, message: '请输入2-28字证书名称', trigger: 'blur' },
        ],
        productAttributeCategoryId: [
          { required: true, message: '请选择证书分类', trigger: 'change' },
        ],
        addressCode: [{ required: true, message: '请选择证书归属地', trigger: 'change' }],
        'attrList[0].attrs': [{ required: true, validator: checked, trigger: 'change' }],
        // certAttachList: [{ required: true, message: '请上传附件', trigger: 'change' }],
        applyFormList: [{ required: true, message: '请上传报名资料', trigger: 'change' }],
        applyMaterial: [{ required: true, message: '请填写报名材料说明', trigger: 'blur' }],
        issuingAgency: [
          { required: true, message: '请输入发证机构', trigger: 'blur' },
          { min: 2, max: 24, message: '请输入2-24字证书名称', trigger: 'blur' },
        ],
      },
    }
  },
  watch: {
    'form.applyMaterial': function(newValue) {
      if (newValue || newValue !== '' || newValue !== undefined || newValue !== null) {
        this.$refs.tinymceRules.clearValidate()
      }
    },
  },
  created() {
    this.attrSelectData()
  },
  methods: {
    async submit(formName) {
      console.log(this.form)
      this.$refs[formName].validate(async valid => {
        if (valid) {
          this.loading = true
          this.form.applyForm = this.form.applyFormList[0].url
          const [, err] = await to(createdCertificate(this.form))
          this.loading = false
          if (err) return this.$message.error(err.msg)
          this.$message.success('添加成功')
          this.$router.push('/certificate/credentials')
        }
      })
    },
    handleAddressChange(value) {
      this.form.provinceCode = value[0]
      this.form.cityCode = value[1]
      for (var i = 0; i < this.total.length; i++) {
        var item = total[i]
        if (item.value == value[0]) {
          this.form.provinceName = item.label
        }
        if (item.children.length > 0) {
          for (var k = 0; k < item.children.length; k++) {
            var twoItem = item.children[k]
            if (twoItem.value == value[1]) {
              this.form.cityName = twoItem.label
            }
          }
        }
      }
    },
    async attrSelectData() {
      const [res, err] = await to(attrSelect({}))
      if (err) return this.$message.error(err.msg)
      this.selectTypeList = res.data
    },
    async changeSelect(val) {
      const [res, err] = await to(attrSelect({}))
      if (err) return this.$message.error(err.msg)
      res.data.map(v => {
        if (v.productAttributeCategoryId == val) {
          if (v.attrDict.specificationList.length !== 0) {
            this.level = v.attrDict.specificationList[0].inputList
            this.form.attrList[0].productAttributeDictId =
              v.attrDict.specificationList[0].productAttributeDictId
          } else {
            this.level = []
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.add_credentials {
  .el-form-item {
    margin-bottom: 30px !important;
    &:last-child {
      margin-top: 50px;
    }
  }
  .tip {
    font-size: 12px;
    color: #909399;
  }
  .uploadFileText {
    color: #909399;
    font-size: 12px;
  }
}
</style>
